import { useTranslations } from 'next-intl';
import { memo, useRef } from 'react';

import { DayPassCtaPrice } from '@/app/[locale]/(search)/coworking/_components/DayPassCtaPrice';
import { Link } from '@/components/Link';
import useAppearOnScroll from '@/hooks/useAppearOnScroll';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';
import { CoworkingDayPassPriceDto } from '@/lib/coworking-day-pass/dto/coworking-day-pass-price.dto';
import { SimpleLocationSearchDto } from '@/lib/locations/dto';
import { DateRange } from '@/models/DateRange';
import { Frequency } from '@/models/Frequency.enum';
import { getFormattedAddressByCountry } from '@/models/location-utils';
import {
  createLocationDayPassUrl,
  createLocationUrl,
  getBrandLogo,
  isExternalLocation,
  isIndustriousLocation,
  shouldShowLogo,
  shouldShowOpeningLabel,
} from '@/utils/locations';

import { Amenity, AmenityCode } from '../Amenity';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import LocationOpeningLabel from '../LocationOpeningLabel';
import SearchCard from '../search/SearchCard';
import LocationCardImage from './LocationCardImage';

type LocationCardProps = {
  priority: boolean;
  location: SimpleLocationSearchDto;
  prices?: CoworkingDayPassPriceDto;
  dateRange?: DateRange;
  cardHeight?: number;
  onMouseEnter: (slug: string) => void;
  onMouseLeave: () => void;
};

const MAX_AMENITIES_DISPLAY = 4;
const MOBILE_MAX_AMENITIES_DISPLAY = 2;

function LocationCard({
  priority,
  location,
  prices,
  dateRange,
  cardHeight,
  onMouseEnter,
  onMouseLeave,
}: LocationCardProps) {
  const ref = useRef(null);
  useAppearOnScroll(ref);
  const screenSize = useScreenSize();

  const t = useTranslations('search');
  const formattedAddress = getFormattedAddressByCountry({
    address: location.address,
    address2: null,
    city: location.city,
    country: location.country,
    state: isIndustriousLocation(location) ? location.state : '',
    zipCode: location.zipCode,
  });

  const showMembershipCta =
    isIndustriousLocation(location) &&
    location.accessMembershipAvailable &&
    prices?.membershipPrice;
  const showDayPassCta =
    isIndustriousLocation(location) &&
    location.coworkingDayPassAvailable &&
    prices?.dayPass;

  const amenities = location.amenities?.unique;

  const isMobile = screenSize < ScreenSize.SM;

  const maxAmenitiesDisplay = isMobile
    ? MOBILE_MAX_AMENITIES_DISPLAY
    : MAX_AMENITIES_DISPLAY;

  return (
    <Link
      href={createLocationUrl(location)}
      target="_blank"
      onMouseEnter={() => onMouseEnter(location.slug)}
      onMouseLeave={onMouseLeave}
      ref={ref}
      data-slug={location.slug}
    >
      <SearchCard className="md:flex-row">
        <LocationCardImage
          height={cardHeight}
          priority={priority}
          location={location}
        />

        <div className="flex flex-col justify-center flex-1 py-4 text-sm truncate md:p-6 md:pt-8">
          <div>
            {shouldShowOpeningLabel(location) && (
              <LocationOpeningLabel
                className="text-xs not-italic font-normal"
                openingDateISO={location.firstDayOfBusinessISO}
                locationGeolocalization={location.geolocalization}
              />
            )}

            <div className="flex items-center justify-between gap-2 max-md:mb-3">
              <h4
                className="flex-1 text-base font-bold truncate md:text-lg text-ocean-60"
                data-testid="location-name"
              >
                {location.name}
              </h4>
              <div className="text-xs md:hidden text-ocean-60 group-hover:block">
                {t('view-location')}
              </div>
            </div>

            <div className="flex flex-wrap gap-2 text-sm text-teal-5">
              <p>
                {formattedAddress.addressLine1} {formattedAddress.addressLine2}
              </p>
              {location.phone && (
                <>
                  <span>|</span>
                  <p>{location.phone}</p>
                </>
              )}
            </div>
            {amenities && amenities.length > 0 && (
              <div className="grid grid-cols-2 gap-2 mt-4 text-sm lg:gap-4">
                {amenities.slice(0, maxAmenitiesDisplay).map((amenity, i) => (
                  <Amenity
                    key={amenity?.code}
                    code={amenity?.code as AmenityCode}
                    name={amenity?.label}
                    labelClassName="text-grey-25"
                    iconClassName="text-grey-25 flex-shrink-0"
                  >
                    {i === maxAmenitiesDisplay - 1 &&
                      amenities.length > maxAmenitiesDisplay && (
                        <span className="pl-1 text-grey-25">
                          {t('and-more-amenities', {
                            count: amenities.length - maxAmenitiesDisplay,
                          })}
                        </span>
                      )}
                  </Amenity>
                ))}
              </div>
            )}
            {(isExternalLocation(location) ||
              shouldShowLogo(location.brandName)) && (
              <ImageWithPlaceholder
                className="mt-3"
                data-testid="brand-logo"
                src={getBrandLogo(location.brandName)}
                alt={`${location.brandName} brand logo`}
                width="150"
                height="10"
                aria-hidden="true"
                quality={100}
              />
            )}
          </div>

          <div className="flex flex-wrap items-start gap-1 mt-4 md:gap-3">
            {showDayPassCta && (
              <DayPassCtaPrice
                href={createLocationDayPassUrl({
                  slug: location.slug,
                  dateRange,
                })}
                price={prices?.dayPass!.price}
                label={t('day-pass-cta-label')}
                currencyIsoCode={prices.currencyIsoCode!}
                frequency={Frequency.Daily}
                dataTestId="daypass-price"
              />
            )}
            {showMembershipCta && (
              <DayPassCtaPrice
                href={`/checkout?slug=${location.slug}`}
                price={prices?.membershipPrice!.price}
                label={
                  prices?.dayPass
                    ? t('membership-cta-label')
                    : t('membership-unlimited-cta-label')
                }
                currencyIsoCode={prices.currencyIsoCode!}
                frequency={Frequency.Monthly}
                dataTestId="membership-price"
                target="_blank"
              />
            )}
          </div>
        </div>
      </SearchCard>
    </Link>
  );
}

export default memo(LocationCard);
